const firebaseConfig = {
  apiKey: "AIzaSyADD99cZidok92t7n91mPIMUNEXoZhkR_A",
  authDomain: "exempla.io",
  databaseURL: "https://ttp-hack.firebaseio.com",
  projectId: "ttp-hack",
  storageBucket: "ttp-hack.appspot.com",
  messagingSenderId: "1085723875443",
  appId: "1:1085723875443:web:43d59e542c5121546f7206",
  measurementId: "G-4ZJL5KQFKB"
};

  export default firebaseConfig